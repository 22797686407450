import React, { useState, useEffect, useCallback } from 'react'
import Badge from '@material-ui/core/Badge'
import NotificationsMenu from './NotificationsMenu'
import { RBAPI } from '../../api/roadboticsAPI'
import Tooltip from '@material-ui/core/Tooltip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { RB_DARK_GREY, RB_YELLOW } from '../../constants/colors'
import Avatar from '@material-ui/core/Avatar'
import NotificationIcon from '../SVGs/NotificationsSVG'
import User from '../../interfaces/user'

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '10px',
    fontWeight: 900,
    color: RB_DARK_GREY,
    padding: theme.spacing(1)
  },
  notificationsDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  badge: {
    marginTop: '-60px'
  },
  avatar: {
    marginBottom: '-5px'
  }
}))

interface NotificationsProps {
  user: User
}

const Notifications = ({ user }: NotificationsProps): JSX.Element => {
  const [allNotifications, setAllNotifications] = useState([])

  const [
    notificationsPriorToLastLogin,
    setNotificationsPriorToLastLogin
  ] = useState(0)

  const [notificationsClicked, setNotificationsClicked] = useState(false)

  const setNewNotifications = async (notifications: []) => {
    // how many notifications are new compared to the last login
    const lastLogin = user.lastLogin
    if (notifications.length > 0 && lastLogin) {
      const createdNotificationsPriorToLastLogin = notifications.reduce(
        (total: number, notification: any) => {
          return new Date(notification?.date) >= new Date(lastLogin) ||
            !user.lastLogin
            ? total + 1
            : total
        },
        0
      )

      setNotificationsPriorToLastLogin(createdNotificationsPriorToLastLogin)
    }
  }

  const getNotifications = useCallback(async () => {
    return RBAPI.getNotifications()
      .then((resp: any) => {
        const sortedList = resp?.notifications.sort(
          (a: any, b: any) => new Date(a.date) < new Date(b.date)
        )
        setAllNotifications(sortedList)
        setNewNotifications(sortedList)
      })
      .catch((e: ErrorEvent) => {
        console.error('Error getting notifications', e)
      })
  }, [user])

  useEffect(() => {
    if (user?.lastLogin) {
      getNotifications()
    }
  }, [user, getNotifications])

  const clickNotifications = (e: React.MouseEvent) => {
    e.preventDefault()
    setNotificationsPriorToLastLogin(0)
    return setNotificationsClicked(!notificationsClicked)
  }

  const classes = useStyles()

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Tooltip title='Notifications' placement='right'>
        <div onClick={clickNotifications} className={classes.avatar}>
          <Avatar
            height='40px'
            width='40px'
            fill={notificationsPriorToLastLogin > 0 ? RB_YELLOW : RB_DARK_GREY}
            variant={'circle'}
            component={NotificationIcon}
          />
          {notificationsPriorToLastLogin > 0 && !notificationsClicked && (
            <Badge
              className={classes.badge}
              badgeContent={notificationsPriorToLastLogin}
              color='primary'
            ></Badge>
          )}
        </div>
      </Tooltip>

      <NotificationsMenu
        clickNotifications={clickNotifications}
        notificationsClicked={notificationsClicked}
        notifications={allNotifications}
      />
    </div>
  )
}

export default Notifications
