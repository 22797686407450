import React, { ReactElement } from 'react'

interface SVGProps {
  fill?: string
  width?: string
  height?: string
}

function AgileMapperIcon({
  fill = '#828282',
  height = '20px',
  width = '20px'
}: SVGProps): ReactElement {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 61.79 59.38'
      fill={fill}
      width={width}
      height={height}
    >
      <path d='M11.2,53.23a5.49,5.49,0,0,1-3.86-1.76c-1.44-1.55-2-3.9-1.7-7l.24-26.45c0-.8,0-1.61,0-2.42,0-1.66,0-3.39,0-5.07,0-2.48-.08-6.23,3.65-7.67a5.49,5.49,0,0,1,6.71,2.77,5.74,5.74,0,0,1,.48,3v.44c0,1,0,2,0,3,0,.53,0,1.06,0,1.58L17,47.62a1.5,1.5,0,0,1-1,1.43,1.52,1.52,0,0,1-1.68-.49,4.8,4.8,0,0,0-2.94-1.67,3.94,3.94,0,0,0-2.67.93,3.55,3.55,0,0,0,.77,1.58,2.47,2.47,0,0,0,1.72.82H53.34V9.4H18.79a1.5,1.5,0,0,1,0-3H54.84a1.5,1.5,0,0,1,1.5,1.5V51.72a1.5,1.5,0,0,1-1.5,1.5H11.2Zm0-9.35a4.58,4.58,0,0,1,.53,0,6.52,6.52,0,0,1,2.34.66l-.21-30.81c0-.51,0-1,0-1.55,0-1.05,0-2.1,0-3.15v-.5A3.13,3.13,0,0,0,13.63,7a2.53,2.53,0,0,0-3-1.31C9.14,6.28,8.91,7.6,9,10.52c0,1.73,0,3.47,0,5.16l0,2.4L8.64,44.42A6.2,6.2,0,0,1,11.15,43.88Z' />
      <path d='M16.87,51.91a1,1,0,0,1-.64-.23,1,1,0,0,1-.13-1.41L47.29,12.79a1,1,0,1,1,1.53,1.28L17.64,51.55A1,1,0,0,1,16.87,51.91Z' />
      <path d='M51.18,51.91a1,1,0,0,1-.57-.18L15,27a1,1,0,0,1,1.15-1.64L51.75,50.09a1,1,0,0,1-.57,1.82Z' />
      <path d='M37.39,41.93a1,1,0,0,1-.79-1.61l8.25-10.63L39,25.39a1,1,0,1,1,1.18-1.61l6.69,4.9a1,1,0,0,1,.4.67,1,1,0,0,1-.2.75L38.18,41.54A1,1,0,0,1,37.39,41.93Z' />
      <polygon points='34.94 22.27 28.95 29.54 23.93 25.95 29.89 18.65 34.94 22.27' />
      <polygon points='27.98 17.07 21.99 24.34 16.97 20.75 22.93 13.45 27.98 17.07' />
      <polygon points='43.23 13.08 37.24 20.35 32.22 16.76 38.18 9.45 43.23 13.08' />
      <polygon points='33.96 51.03 26.29 45.57 30.24 40.86 37.78 46.19 33.96 51.03' />
      <path d='M51.37,34.22a1,1,0,0,1-.59-.2l-5.1-3.72a1,1,0,0,1-.4-.68,1,1,0,0,1,.21-.75l4.32-5.4a1,1,0,0,1,1.4-.16,1,1,0,0,1,.16,1.41L47.7,29.3,52,32.41a1,1,0,0,1,.22,1.4A1,1,0,0,1,51.37,34.22Z' />
    </svg>
  )
}

export default AgileMapperIcon
