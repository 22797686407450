import React, { ReactElement } from 'react'

interface SVGProps {
  fill?: string
  width?: string
  height?: string
}

function SettingsIcon({
  fill = '#828282',
  height = '20px',
  width = '20px'
}: SVGProps): ReactElement {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 61.79 59.38'
      fill={fill}
      width={width}
      height={height}
    >
      <path d='M27.78,5.15h6.09a2.5,2.5,0,0,1,.88,1.08q1,2.19,2,4.39c.23.5.43,1,.64,1.49l2.35,1c1.11-.49,2.22-1,3.34-1.46,1-.4,1.95-.74,2.92-1.12a1.29,1.29,0,0,1,1.55.38s0,0,0,0C48.69,12,49.82,13.16,51,14.26a1.18,1.18,0,0,1,.36,1.32c-.29.86-.58,1.73-.91,2.57-.47,1.16-1,2.3-1.47,3.45a.69.69,0,0,0-.08.58c.3.69.59,1.39.88,2.08l.45.17c1.59.61,3.19,1.2,4.77,1.82a11.51,11.51,0,0,1,1.44.74.88.88,0,0,1,.46.84c0,1.77,0,3.54,0,5.32a1,1,0,0,1-.45.85,5.48,5.48,0,0,1-.74.43C54.34,35,53,35.6,51.61,36.17c-.61.26-1.22.5-1.82.74l-.94,2.25a1.7,1.7,0,0,0,.12.3c.52,1.15,1.06,2.3,1.57,3.45a17.53,17.53,0,0,1,1,2.64,1.08,1.08,0,0,1-.35,1.18c-.44.4-.85.82-1.27,1.24-.76.74-1.53,1.48-2.28,2.24a1.21,1.21,0,0,1-1.36.39c-.22-.08-.46-.1-.68-.18L41.87,49c-.72-.28-1.43-.58-2.13-.87l-2.35,1c-.51,1.3-1,2.58-1.5,3.84-.34.83-.74,1.63-1.13,2.43a1.19,1.19,0,0,1-1.23.7c-1.64,0-3.28,0-4.92,0a1.32,1.32,0,0,1-1.31-.74c-.08-.14-.16-.26-.23-.4-.37-.79-.76-1.58-1.1-2.38-.5-1.15-1-2.3-1.45-3.44l-2.35-1c-1.13.5-2.23,1-3.36,1.47s-2,.77-3,1.15a1.19,1.19,0,0,1-1.34-.32q-1.86-1.83-3.73-3.65a.88.88,0,0,1-.28-.78,2.13,2.13,0,0,1,.07-.44c.33-.93.64-1.85,1-2.76.43-1.08.92-2.14,1.36-3.21a.77.77,0,0,0,.09-.52c-.27-.73-.59-1.43-.88-2.13-.71-.27-1.4-.51-2.07-.78-1.33-.52-2.66-1.06-4-1.61A2.15,2.15,0,0,1,5,33.75V27.67A2.44,2.44,0,0,1,6,26.85L10.26,25l1.8-.73L13,22.05c0-.08,0-.15-.08-.21-.54-1.18-1.09-2.35-1.62-3.54a16.71,16.71,0,0,1-1-2.69,1,1,0,0,1,.32-1.09L12,13.21l2.31-2.27a1.14,1.14,0,0,1,1.24-.34c.24.09.5.11.73.2l4.48,1.72c.47.18.94.38,1.4.57l2.35-1c.3-.77.58-1.52.89-2.27.5-1.21,1-2.42,1.54-3.63A2.15,2.15,0,0,1,27.78,5.15ZM31.1,39.37c.45-.06,1.08-.11,1.69-.23a8.75,8.75,0,0,0,7-9.46,8.14,8.14,0,0,0-2-4.65,8.73,8.73,0,0,0-8-3.05,8.59,8.59,0,0,0-6,3.49,8.41,8.41,0,0,0-.61,9.34A9,9,0,0,0,31.1,39.37Z' />
    </svg>
  )
}

export default SettingsIcon
