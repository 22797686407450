import React, { ReactElement } from 'react'

interface SVGProps {
  fill?: string
  width?: string
  height?: string
}

function NotificationsIcon({
  fill = '#828282',
  height = '20px',
  width = '20px'
}: SVGProps): ReactElement {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 80 80'
      fill={fill}
      width={width}
      height={height}
    >
      <path
        d='M37.78,1A36.48,36.48,0,1,0,74.26,37.51,36.48,36.48,0,0,0,37.78,1Zm.55,58.12c-2.95.21-4.5-.82-5.74-4.16H43C42.3,57.65,40.75,59,38.33,59.15ZM15.77,51.38c1.37-1.71,2.39-3.07,3.51-4.34a14.71,14.71,0,0,0,3.55-9.61c.12-3.48.1-7,.54-10.43.75-6.07,4-10.24,10-12.08a4.69,4.69,0,0,0,2.16-1.75c1.39-1.9,3.4-1.93,4.87,0a5.16,5.16,0,0,0,2.15,1.92c8,2.35,10.11,8.56,10.55,16,.18,2.9.43,5.8.77,8.68a16.28,16.28,0,0,0,4.65,9.71,15.78,15.78,0,0,1,1.29,2Z'
        className='cls-1'
      />
    </svg>
  )
}

export default NotificationsIcon
