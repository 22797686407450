import React from 'react'
import ColorPreference from './ColorPreference'

interface ColorPreferenceContainerInterface {
  colorBlindMode: string
  updateColorBlindMode: (event: React.ChangeEvent<{ value: string }>) => void
}

const ColorPreferenceContainer = ({
  colorBlindMode,
  updateColorBlindMode
}: ColorPreferenceContainerInterface) => {
  // handles the changing of the the specific color blind modes

  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    updateColorBlindMode(event)
  }

  return (
    <ColorPreference colorValue={colorBlindMode} handleChange={handleChange} />
  )
}

export default ColorPreferenceContainer
