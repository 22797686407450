import React, { ReactElement } from 'react'

interface SVGProps {
  fill?: string
  width?: string
  height?: string
}

function FAQICON({
  fill = '#828282',
  height = '20px',
  width = '20px'
}: SVGProps): ReactElement {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 80 80'
      fill={fill}
      width={width}
      height={height}
    >
      <path
        className='cls-1'
        d='M37.72,1A36.48,36.48,0,1,0,74.2,37.51,36.48,36.48,0,0,0,37.72,1Zm3.15,57.49A6.51,6.51,0,0,1,36.36,60a6.7,6.7,0,0,1-4.62-1.48,5.32,5.32,0,0,1-1.67-4.21,5.47,5.47,0,0,1,1.6-4.23,6.63,6.63,0,0,1,4.69-1.49,6.42,6.42,0,0,1,4.57,1.5,5.54,5.54,0,0,1,1.59,4.22A5.36,5.36,0,0,1,40.87,58.52ZM50.58,32.2a17,17,0,0,1-5.38,5,20.89,20.89,0,0,0-3.91,3,3.34,3.34,0,0,0-.79,2.22v1.52H31.35V41.33a9.48,9.48,0,0,1,1.2-4.93,12.45,12.45,0,0,1,4.22-3.91,16.07,16.07,0,0,0,3.47-2.74A4.15,4.15,0,0,0,41.3,27a2.27,2.27,0,0,0-1.13-2,5.42,5.42,0,0,0-3-.72q-4.5,0-10.51,3.18l-4.14-8.1a30.8,30.8,0,0,1,15.49-4.23q6.63,0,10.42,2.92a9.52,9.52,0,0,1,3.78,8A11.33,11.33,0,0,1,50.58,32.2Z'
      />
    </svg>
  )
}

export default FAQICON
