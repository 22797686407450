import React, { useState, useEffect } from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ProfileSettings from '../ProfileSettings/ProfileSettings'
import User from '../../interfaces/user'
import { RBAPI } from '../../api/roadboticsAPI'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Membership from '../../interfaces/membership'
import Organization from '../../interfaces/organization'
import AccountAvatar from './Avatar'

interface AccountProps {
  user: User
  logout: (e: React.MouseEvent) => void
  isDemo: boolean
}

const useStyles = makeStyles({
  email: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 12,
    textAlign: 'left',
    padding: '0px 15px 3px',
    textTransform: 'none'
  },
  name: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 18,
    textAlign: 'left',
    padding: '0px 15px 3px',
    textTransform: 'none',
    fontWeight: 700
  },
  org: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    textAlign: 'left',
    padding: '0px 15px 15px',
    textTransform: 'none',
    fontWeight: 500
  },
  popoverPaper: {
    border: '1px solid black',
    marginLeft: '15px'
  }
})

const Account = ({ user, logout, isDemo }: AccountProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [firstName, setFirstName] = useState(user.firstName || '')
  const [lastName, setLastName] = useState(user.lastName || '')
  const [email, setUserEmail] = useState(user.email || '')
  const [organizationName, setOrganizationName] = useState('')
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false)

  const getUsersMemberships = async (userUID: string) => {
    const memberships = await RBAPI.fetchMembershipsByUsers(userUID).catch(
      (err: any) => {
        if (err?.response?.status === 404) {
          return
        }
        throw err
      }
    )
    return memberships
  }

  const getOrganization = async (orgId: string) => {
    return await RBAPI.getOrganization(orgId).catch((err: any) => {
      if (err?.response?.status === 404) {
        return
      }
      throw err
    })
  }

  const setUserOrganizationName = async (userUID: string) => {
    const memberships = (await getUsersMemberships(userUID)) as Membership[]
    if (memberships.length > 1) {
      setOrganizationName('')
    } else {
      const organization = (await getOrganization(
        memberships[0].organizationId
      )) as Organization

      setOrganizationName(organization.name)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setIsPopoverOpen(!isPopoverOpen)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (_e: React.MouseEvent) => {
    setIsPopoverOpen(false)
    setAnchorEl(null)
  }

  const saveProfile = () => {
    const newUser = {
      ...user,
      firstName: firstName,
      lastName: lastName
    }

    RBAPI.updateUser(newUser).catch((e: ErrorEvent) => console.error(e))
  }

  useEffect(() => {
    if (user && user.firstName && user.lastName) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
    }
    if (user && user.email) {
      setUserEmail(user.email)
    }
    if (user && user.id) {
      setUserOrganizationName(user.id)
    }
  }, [user])

  const closeProfileDialog = (): void => {
    setFirstName(user.firstName ? user.firstName : '')
    setLastName(user.lastName ? user.lastName : '')
    setIsProfileDialogOpen(false)
  }

  const openProfileDialog = (): void => {
    setIsProfileDialogOpen(true)
  }

  const classes = useStyles(isPopoverOpen)
  return (
    <>
      <AccountAvatar
        firstName={firstName}
        lastName={lastName}
        email={email}
        handleClick={handleClick}
      />
      <Popover
        PaperProps={{ className: classes.popoverPaper }}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List>
          <Typography className={classes.name}>
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography noWrap={true} className={classes.email}>
            {`${user.email}`.toLowerCase()}
          </Typography>
          <Typography className={classes.org}>{organizationName}</Typography>
          <Divider></Divider>
          <ListItem button onClick={openProfileDialog}>
            <ListItemText primary='Profile' />
          </ListItem>
          <ListItem button onClick={logout} disabled={isDemo}>
            <ListItemText primary='Sign Out' />
          </ListItem>
        </List>
      </Popover>

      <ProfileSettings
        isDialogOpen={isProfileDialogOpen}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        saveProfile={saveProfile}
        user={user}
        disabled={isDemo}
        closeProfileDialog={closeProfileDialog}
        setIsDialogOpen={setIsProfileDialogOpen}
      />
    </>
  )
}

export default Account
