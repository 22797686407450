import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import BetaVisibility from './BetaVisibility'
import ActivityIndicatorButton from '../CustomComponents/ActivityIndicatorButton'

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px',
    marginTop: '1%'
  },
  buttons: {
    padding: '0px 12px 20px 0px',
    bottom: '0px',
    right: '10px',
    position: 'absolute'
  },
  newCard: {
    paddingBottom: '50px'
  },
  radio: {
    margin: '-8px 0px -8px 3px'
  },
  divider: {
    marginBottom: '2%'
  },
  unitsDiv: {
    marginTop: '2%'
  },
  group: {
    marginLeft: '-2%'
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '28px'
  },
  root: {
    boxShadow: 'none'
  }
})

interface AppearanceSettingsInterface {
  isGeneralSettingsActive: boolean;
  saveGeneralSettings: () => void;
  disabled: boolean;
  isBetaUser: boolean;
  setIsBetaUser: (value: boolean) => void;
}

const GeneralSettings = ({
  isGeneralSettingsActive,
  saveGeneralSettings,
  disabled,
  isBetaUser,
  setIsBetaUser,
}: AppearanceSettingsInterface): JSX.Element => {
  const classes = useStyles()
  return (
    <div>
      <Card className={classes.root} raised={false}>
        <React.Fragment>
          <CardContent>
            <Typography className={classes.typography}>
              Beta Features
              </Typography>
          </CardContent>
          <Divider />
          <CardContent>
            <BetaVisibility
              isBetaUser={isBetaUser}
              setIsBetaUser={setIsBetaUser}
            />
          </CardContent>
        </React.Fragment>
        <Divider />

        <CardActions style={{ float: 'right' }}>
          <ActivityIndicatorButton
            isActive={isGeneralSettingsActive}
            onClick={saveGeneralSettings}
            disabled={disabled}
          >
            Save Settings
          </ActivityIndicatorButton>
        </CardActions>
      </Card>
    </div>
  )
}

export default GeneralSettings
