import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
  AccordionActions,
  AccordionSummary,
  Accordion,
  IconButton,
  Snackbar
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { RWFirebase } from '../../api/roadboticsAPI'
import Close from '@material-ui/icons/Close'

const styles = makeStyles({
  fieldDiv: { margin: '2% 0' },
  textField: {
    textAlign: 'center',
    width: '50%'
  },
  wholeDiv: {
    margin: '25px auto',
    textAlign: 'center',
    border: '1px red solid',
    background: `#f5f5f5`
  },
  details: {
    textAlign: 'center'
  }
})

interface PasswordSettingsProps {
  disabled: boolean
}

const PasswordSettingsContainer = ({
  disabled
}: PasswordSettingsProps): JSX.Element => {
  const [newPassword, setNewPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [isSnackBarOpen, setIsSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleNewPassword = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (currentPassword !== newPassword) {
      if (newPassword.length >= 8) {
        const firebaseUser = RWFirebase.auth().currentUser

        const credential = RWFirebase.auth.EmailAuthProvider.credential(
          firebaseUser.email,
          currentPassword
        )
        firebaseUser
          .reauthenticateWithCredential(credential)
          .then(
            (resp: {
              user: { updatePassword: (arg0: string) => Promise<any> }
            }) => {
              resp.user
                .updatePassword(newPassword)
                .then(() => {
                  setIsSnackbarOpen(true)
                  setSnackbarMessage('Password Updated')
                })
                .catch((e: ErrorEvent) => {
                  setIsSnackbarOpen(true)
                  setSnackbarMessage(`Could not update password ${e}`)
                })
            }
          )
          .catch((e: any) => {
            if (e.code === 'auth/wrong-password') {
              setIsSnackbarOpen(true)
              setSnackbarMessage(
                "The current password you've entered is incorrect."
              )
            }
            console.error('Error Reauthenticating', e)
          })
      } else {
        setIsSnackbarOpen(true)
        setSnackbarMessage('The new password must be 8 characters or more')
      }
    } else {
      setIsSnackbarOpen(true)
      setSnackbarMessage(
        'The new password cannot be the same as the current password'
      )
    }
  }

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false)
    setSnackbarMessage('')
  }

  const classes = styles()
  return (
    <div>
      <Card className={classes.wholeDiv} raised={false}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h6'>Change Password</Typography>
          </AccordionSummary>
          <form onSubmit={handleNewPassword}>
            <div className={classes.fieldDiv}>
              <Typography>Current Password:</Typography>
              <TextField
                id='old-password'
                label='Current Password'
                value={currentPassword}
                variant='outlined'
                margin='dense'
                onChange={(e) => setCurrentPassword(e.target.value)}
                inputProps={{ type: 'password' }}
                className={classes.textField}
              />
            </div>
            <div className={classes.fieldDiv}>
              <Typography>New Password:</Typography>
              <TextField
                disabled={!currentPassword}
                id='new-password'
                label='New Password'
                margin='dense'
                value={newPassword}
                variant='outlined'
                onChange={(e) => setNewPassword(e.target.value)}
                inputProps={{ type: 'password' }}
                className={classes.textField}
              />
            </div>
            <AccordionActions>
              <Button variant='contained' color='primary' type={'submit'} disabled={disabled}>
                Update Password
              </Button>
            </AccordionActions>
          </form>
        </Accordion>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id='message-id'>{snackbarMessage}</span>}
        action={
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={handleCloseSnackbar}
          >
            <Close />
          </IconButton>
        }
      />
    </div>
  )
}

export default PasswordSettingsContainer
