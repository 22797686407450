import React from 'react'

import Button from '@material-ui/core/Button'
import MenuBookRounded from '@material-ui/icons/MenuBookRounded'
import OndemandVideoRounded from '@material-ui/icons/OndemandVideoRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  divContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  title: {
    marginLeft: '7px'
  }
}))

interface NotificationMenuProps {
  notifications: any
  notificationsClicked: boolean
  clickNotifications: (event: React.MouseEvent) => void
}
const NotificationMenu = ({
  notifications,
  notificationsClicked,
  clickNotifications
}: NotificationMenuProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Dialog open={notificationsClicked} onClose={clickNotifications}>
      <DialogTitle>Notifications</DialogTitle>
      <DialogContent>
        {notifications.length ? (
          notifications.map((child: any) => (
            <div key={`notification-menu-${child.date}`}>
              <Button href={child.link} target='_blank'>
                <div>
                  {child.type === 'blog' ? (
                    <MenuBookRounded />
                  ) : (
                    <OndemandVideoRounded />
                  )}
                </div>
                <p className={classes.title}>{`${child.title}`}</p>
              </Button>
              <br />
            </div>
          ))
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={clickNotifications}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationMenu
