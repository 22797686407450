import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import {
  PRIMARY_HOVER_COLOR,
  RB_GREEN,
  RB_LIGHT_GREEN,
  RB_YELLOW,
  RB_ORANGE,
  RB_RED
} from '../../constants/colors'

const useStyles = makeStyles({
  root: {
    marginTop: '20px'
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px',
    zIndex: 1000
  },
  radio: {
    margin: '-8px 0px -8px 3px',
    '&$checked': {
      color: PRIMARY_HOVER_COLOR
    }
  },
  checked: {},
  checkboxItem: {
    display: 'inline-block'
  },
  key: {
    display: 'inline-flex'
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    margin: '2px'
  },
  legend: {
    position: 'relative',
    paddingLeft: '470px',
    bottom: '170px',
    borderBottomColor: '#f5f5f5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    margin: '0px 40px'
  },
  color: {
    marginBottom: '-140px'
  },
  formItem: {
    padding: '2px 0px'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none'
  },
  table: {
    marginTop: '2%',
    marginBottom: '2%'
  }
})

interface ColorPreferenceInterface {
  colorValue: string
  handleChange: (event: React.ChangeEvent<{ value: string }>) => void
}

const ColorPreference = ({
  colorValue,
  handleChange
}: ColorPreferenceInterface) => {
  const classes = useStyles()
  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <FormControlLabel
              value=''
              control={
                <Radio
                  onChange={handleChange}
                  checked={colorValue === ''}
                  classes={{ root: classes.radio }}
                  color='primary'
                />
              }
              label='Normal'
              className={classes.formItem}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <div className={classes.key}>
              <div
                className={classes.circle}
                style={{ backgroundColor: RB_GREEN }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: RB_LIGHT_GREEN }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: RB_YELLOW }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: RB_ORANGE }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: RB_RED }}
              />
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tableCell}>
            <FormControlLabel
              value='deuteranopia'
              control={
                <Radio
                  onChange={handleChange}
                  checked={colorValue === 'deuteranopia'}
                  classes={{ root: classes.radio }}
                  color='primary'
                />
              }
              label='Deuteranopia'
              className={classes.formItem}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <div className={classes.key}>
              <div
                className={classes.circle}
                style={{ backgroundColor: '#051268' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#7c99cf' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#cfba7c' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#bf8f00' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#735600' }}
              />
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tableCell}>
            <FormControlLabel
              value='protanopia'
              control={
                <Radio
                  onChange={handleChange}
                  checked={colorValue === 'protanopia'}
                  classes={{ root: classes.radio }}
                  color='primary'
                />
              }
              label='Protanopia'
              className={classes.formItem}
            />
          </TableCell>

          <TableCell className={classes.tableCell}>
            <div className={classes.key}>
              <div
                className={classes.circle}
                style={{ backgroundColor: '#051268' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#7c99cf' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#cfba7c' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#bf8f00' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#735600' }}
              />
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tableCell}>
            <FormControlLabel
              value='tritanopia'
              control={
                <Radio
                  onChange={handleChange}
                  checked={colorValue === 'tritanopia'}
                  classes={{ root: classes.radio }}
                  color='primary'
                />
              }
              label='Tritanopia'
              className={classes.formItem}
            />
          </TableCell>

          <TableCell className={classes.tableCell}>
            <div className={classes.key}>
              <div
                className={classes.circle}
                style={{ backgroundColor: '#004f3e' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#2b806e' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#a0ded1' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#ed8282' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: RB_RED }}
              />
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.tableCell}>
            <FormControlLabel
              value='achromatopia'
              control={
                <Radio
                  onChange={handleChange}
                  checked={colorValue === 'achromatopia'}
                  classes={{ root: classes.radio }}
                  color='primary'
                />
              }
              label='Achromatopia'
              className={classes.formItem}
            />
          </TableCell>

          <TableCell className={classes.tableCell}>
            <div className={classes.key}>
              <div
                className={classes.circle}
                style={{ backgroundColor: '#c9c9c9' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#a3a3a3' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#808080' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#595959' }}
              />
              <div
                className={classes.circle}
                style={{ backgroundColor: '#454545' }}
              />
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ColorPreference
