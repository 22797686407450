import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px'
  },
  checkbox: {
    marginLeft: '-2%'
  }
})

interface ExampleOrganizationVisiblityInterface {
  hideExampleOrganizations: boolean
  setHideExampleOrganizations: (value: boolean) => void
}

const ExampleOrganizationVisibility = ({
  hideExampleOrganizations,
  setHideExampleOrganizations
}: ExampleOrganizationVisiblityInterface): React.ReactElement => {
  const classes = useStyles()
  const onChange = (): void => {
    setHideExampleOrganizations(!hideExampleOrganizations)
  }

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={hideExampleOrganizations}
            onChange={onChange}
            name='hideExampleOrganizations'
            color='primary'
            className={classes.checkbox}
          />
        }
        label='Hide Example Organizations'
      />
    </div>
  )
}

export default ExampleOrganizationVisibility
