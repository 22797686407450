import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { RB_DARK_GREY } from '../../constants/colors'
import Avatar from '@material-ui/core/Avatar'
import FAQICON from '../SVGs/FAQSVG'

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '10px',
    fontWeight: 900,
    color: RB_DARK_GREY,
    padding: theme.spacing(1)
  },
  notificationsDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  badge: {
    marginTop: '-60px'
  },
  avatar: {
    marginBottom: '-5px'
  }
}))

const FAQ = (): JSX.Element => {
  const classes = useStyles()

  const clickFAQ = () => {
    window.open(
      'https://support.roadbotics.com/hc/en-us',
      '_blank'
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Tooltip title='FAQ' placement='right'>
        <div onClick={clickFAQ} className={classes.avatar}>
          <Avatar
            height='40px'
            width='40px'
            fill={RB_DARK_GREY}
            variant={'circle'}
            component={FAQICON}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default FAQ
