import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button, { ButtonProps } from "./Button";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles({
  root: {
    marginRight: "8px",
  },
});

interface ActivityIndicatorButtonProps extends ButtonProps {
  id?: string;
  isActive?: boolean;
  progressSize?: number;
  disableButtonWhileActive?: boolean;
}

const ActivityIndicatorButton = ({
  isActive = false,
  progressSize = 14,
  disableButtonWhileActive = true,
  disabled = false,
  children = null,
  ...buttonProps
}: ActivityIndicatorButtonProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <Button
      {...buttonProps}
      disabled={disableButtonWhileActive ? isActive || disabled : disabled}
      variant="contained"
      color="primary"
      capitalizeLabel
    >
      {isActive && (
        <Grow in={isActive}>
          <CircularProgress
            classes={{ root: classes.root }}
            size={progressSize}
          />
        </Grow>
      )}
      {children}
    </Button>
  );
};
// ActivityIndicatorButton.defaultProps = defaultProps;

export default ActivityIndicatorButton;
