import React, { ReactElement } from 'react'

interface SVGProps {
  fill?: string
  width?: string
  height?: string
}

function RoadWayIcon({
  fill = '#828282',
  height = '20px',
  width = '20px'
}: SVGProps): ReactElement {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 61.79 59.38'
      fill={fill}
      width={width}
      height={height}
    >
      <path d='M52.34,12.47l-.5-.45,4.36-3-.43-.35L32.85,18.93a18.13,18.13,0,0,1,.25,3,16.38,16.38,0,0,1-.3,3.1l2.71-1.84L37,24.67,31.7,28.85C31.26,30,30.77,31,30.3,32c-.61,1.21-1.26,2.39-1.9,3.53s-1.15,2-1.66,3c-1.69,3.32-3.39,6.71-5,10l-.06.11L57,9.66l-.52-.43ZM39.12,23l-1.25-1.41L43,18,44,19.1Zm7-5.54-.77-1,4.92-3.35.64.52Z' />
      <polygon points='15.08 55.87 15.18 55.76 15.09 55.56 15.08 55.87' />
      <polygon points='57.13 8.68 56.54 8.82 56.2 9.05 56.43 9.23 57.13 8.68' />
      <path d='M28.72,31a20.15,20.15,0,0,0,2.57-9.1c0-8.41-6.27-15.26-14-15.26s-14,6.85-14,15.26c0,4,2.15,7.63,3.74,11.22,1.22,2.77,2.46,5.53,3.7,8.29l5.88,13.1c2.85-5.64,5.65-11.32,8.53-16.95C26.26,35.41,27.59,33.24,28.72,31ZM17.3,14.53c3.73,0,6.76,3.31,6.76,7.37s-3,7.37-6.76,7.37S10.54,26,10.54,21.9,13.58,14.53,17.3,14.53Z' />{' '}
    </svg>
  )
}

export default RoadWayIcon
