import roadboticsAPI from 'roadbotics-api-wrapper'

let RBAPI: any
let RWFirebase: any

export const initAPI = (
  apiBase: string,
  accountsDomain: string,
  firebase: any
): void => {
  if (!RBAPI) {
    RBAPI = new roadboticsAPI(apiBase, accountsDomain, firebase)
    RWFirebase = firebase
  }
}
export { RBAPI, RWFirebase }
