import React from 'react'
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles'
import SideBar from '../SideBar/SideBar'
import { RBAPI, initAPI } from '../../api/roadboticsAPI'

const generateClassName = createGenerateClassName({
  productionPrefix: 'roadbotics-os-sidebar',
  disableGlobal: true
})

interface AppProps {
  children?: React.ReactNode
  firebase?: any
  apiBase?: string
  accountsDomain?: string
  userUId: string
}

const App = ({
  children,
  firebase,
  apiBase,
  accountsDomain,
  userUId
}: AppProps): React.ReactElement => {
  if (apiBase && accountsDomain && firebase) {
    initAPI(apiBase, accountsDomain, firebase)
  }

  const logoutOfFirebaseAndRedirect = (redirectURL: string) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.replace(redirectURL)
      })
  }

  const logout = (_e: React.MouseEvent) => {
    RBAPI.logoutSession().then((resp: any) => {
      // log out of firebase and redirect to auth
      const redirectURL = resp?.data?.accountsRedirectURL
      logoutOfFirebaseAndRedirect(redirectURL)
    })
  }

  return (
    <StylesProvider generateClassName={generateClassName}>
      <SideBar logout={logout} userUId={userUId}>
        {children}
      </SideBar>
    </StylesProvider>
  )
}

export default App
