import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  Snackbar,
  IconButton
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import AppearanceSettings from '../AppearanceSettings/AppearanceSettings'
import { RBAPI } from '../../api/roadboticsAPI'

import makeStyles from '@material-ui/core/styles/makeStyles'
import { RB_DARK_GREY } from '../../constants/colors'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import GeneralSettings from '../GeneralSettings/GeneralSettings'

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '10px',
    fontWeight: 900,
    color: RB_DARK_GREY,
    padding: theme.spacing(1)
  },
  notificationsDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  notificationsBadge: {
    marginLeft: '30px'
  },
  dialog: {
    width: '100%'
  }
}))

interface SettingsDialogInterface {
  setIsDialogOpen: (e: MouseEvent) => void
  isDialogOpen: boolean
  userSettings: { colorMode: string; measuringSystem: string, isBetaUser: boolean }
  isFreeTrialUser: boolean
  userUId: string
  closeSettings: (event: React.MouseEvent) => void
}

interface TabContainerInterface {
  children: JSX.Element
}

function TabContainer({ children }: TabContainerInterface) {
  return (
    <Typography component='div' style={{ padding: 24 }}>
      {children}
    </Typography>
  )
}

const SettingsDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  userUId,
  userSettings,
  closeSettings,
  isFreeTrialUser
}: SettingsDialogInterface): JSX.Element => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [measuringSystem, setMeasuringSystem] = useState(
    userSettings.measuringSystem
  )
  const [colorBlindMode, setColorBlindMode] = useState(userSettings.colorMode)
  const [hideExampleOrganizations, setHideExampleOrganizations] = useState(
    false
  )
  const [isBetaUser, setIsBetaUser] = useState(userSettings.isBetaUser)
  const [isSnackBarOpen, setIsSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [isGeneralSettingsActive, setIsGeneralSettingsActive] = useState(false);
  const [isAppearanceSettingsActive, setIsAppearanceSettingsActive] = useState(false);

  useEffect(() => {
    setColorBlindMode(userSettings.colorMode)
    setMeasuringSystem(userSettings.measuringSystem)
    setIsBetaUser(userSettings.isBetaUser)
  }, [userSettings])

  const handleTabChange = (
    _: React.ChangeEvent<{}>,
    tabValue: number
  ): void => {
    setTabValue(tabValue)
  }

  const saveGeneralSettings = () => {
    setIsGeneralSettingsActive(true)
    const editedSettings = {
      isBetaUser: isBetaUser
    }
    // logout of the session only if the setting has changed (accounts for other general settings) 
    // and before setting the user to beta or vice-versa
    if (userSettings.isBetaUser !== isBetaUser) {
      RBAPI.logoutSession();
    }
    RBAPI.updateUserSettings(userUId, editedSettings)
      .catch((err: ErrorEvent) => {
        console.error(err)
      })
      .then(() => {
        setIsSnackbarOpen(true)
        setSnackbarMessage('General Settings Saved')
      }).finally(() => {
        setIsGeneralSettingsActive(false)
      })
  }

  const saveAppearanceSettings = () => {
    setIsAppearanceSettingsActive(true)
    const editedSettings = {
      colorMode: colorBlindMode,
      measuringSystem: measuringSystem,
      hideExampleOrganizations: hideExampleOrganizations,
      isBetaUser: isBetaUser
    }
    RBAPI.updateUserSettings(userUId, editedSettings)
      .catch((err: ErrorEvent) => {
        console.error(err)
      })
      .then(() => {
        setIsSnackbarOpen(true)
        setSnackbarMessage('Appearance Settings Saved')
      }).finally(() => {
        setIsAppearanceSettingsActive(false)
      })
  }

  const resetAppearanceSettings = () => {
    const defaultSettings = {
      colorMode: '',
      measuringSystem
    }

    setColorBlindMode(defaultSettings.colorMode)

    RBAPI.updateUserSettings(userUId, defaultSettings)
      .catch((err: ErrorEvent) => {
        console.error(err)
      })
      .then(() => {
        setIsSnackbarOpen(true)
        setSnackbarMessage('Appearance Settings Saved')
      })
  }

  const updateMeasuringSystem = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setMeasuringSystem(event.target.value)
  }

  const updateColorBlindMode = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setColorBlindMode(event.target.value)
  }

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false)
    setSnackbarMessage('')
  }

  const freeTrialUser = isFreeTrialUser || false

  return (
    <Dialog
      open={isDialogOpen}
      onClose={setIsDialogOpen}
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <div className={classes.dialog}>
          <Tabs
            value={tabValue}
            indicatorColor='primary'
            centered={true}
            onChange={handleTabChange}
          >
            <Tab label='Appearance' value={0} />
            <Tab label='General' value={1} />
          </Tabs>
          {tabValue === 0 && (
            <TabContainer>
              <div>
                <AppearanceSettings
                  isAppearanceSettingsActive={isAppearanceSettingsActive}
                  saveAppearanceSettings={saveAppearanceSettings}
                  resetAppearanceSettings={resetAppearanceSettings}
                  measuringSystem={measuringSystem}
                  updateMeasuringSystem={updateMeasuringSystem}
                  disabled={process.env.REACT_APP_ISDEMO === '1'}
                  colorBlindMode={colorBlindMode}
                  updateColorBlindMode={updateColorBlindMode}
                  freeTrialUser={freeTrialUser}
                  hideExampleOrganizations={hideExampleOrganizations}
                  setHideExampleOrganizations={setHideExampleOrganizations}
                />
              </div>
            </TabContainer>
          )}
          {tabValue === 1 && (
            <TabContainer>
              <div>
                <GeneralSettings
                  isGeneralSettingsActive={isGeneralSettingsActive}
                  saveGeneralSettings={saveGeneralSettings}
                  disabled={process.env.REACT_APP_ISDEMO === '1'}
                  isBetaUser={isBetaUser}
                  setIsBetaUser={setIsBetaUser}
                />
              </div>
            </TabContainer>
          )}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={isSnackBarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id='message-id'>{snackbarMessage}</span>}
          action={
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={handleCloseSnackbar}
            >
              <Close />
            </IconButton>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSettings}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SettingsDialog
