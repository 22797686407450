import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import User from '../../interfaces/user'
import PasswordSettingsContainer from '../PasswordSettings/PasswordSettingsContainer'

const useStyles = makeStyles({
  actions: {
    float: 'right'
  },
  content: {
    textAlign: 'center'
  },
  dialogPaper: {
    width: '100%'
  },
  fieldDiv: {
    margin: '2%'
  },
  root: {
    background: `#f5f5f5`
  },
  textField: {
    textAlign: 'center',
    width: '80%'
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '28px'
  }
})

interface ProfileProps {
  isDialogOpen: boolean
  user: User
  firstName: string
  setFirstName: (value: string) => void
  lastName: string
  setLastName: (value: string) => void
  saveProfile: () => void
  closeProfileDialog: () => void
  setIsDialogOpen: (value: boolean) => void
  disabled: boolean
}

const ProfileSettings = ({
  isDialogOpen,
  user,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  saveProfile,
  closeProfileDialog,
  setIsDialogOpen,
  disabled
}: ProfileProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        PaperProps={{ className: classes.dialogPaper }}
        open={isDialogOpen}
        onClose={closeProfileDialog}
      >
        <DialogContent className={classes.content}>
          <DialogTitle>
            <Typography className={classes.typography}>
              Modify Account
            </Typography>
          </DialogTitle>

          <Divider />
          <div className={classes.fieldDiv}>
            <Typography>Email:</Typography>
            <TextField
              disabled
              id='email'
              label='email'
              value={user.email}
              margin='dense'
              variant='outlined'
              className={classes.textField}
            />
          </div>

          <div className={classes.fieldDiv}>
            <Typography>First Name:</Typography>
            <TextField
              id='first-name'
              label='First Name'
              value={firstName}
              margin='dense'
              variant='outlined'
              onChange={(e: any) => setFirstName(e.target.value)}
              className={classes.textField}
            />
          </div>
          <div className={classes.fieldDiv}>
            <Typography>Last Name:</Typography>
            <TextField
              id='last-name'
              label='Last Name'
              value={lastName}
              margin='dense'
              variant='outlined'
              onChange={(e: any) => setLastName(e.target.value)}
              className={classes.textField}
            />
          </div>
          <PasswordSettingsContainer disabled={disabled} />
          <Divider />
          <DialogActions>
            <Button onClick={closeProfileDialog} disabled={disabled}>
              Close
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                saveProfile()
                setIsDialogOpen(false)
              }}
              color='primary'
              type='submit'
              disabled={disabled}
            >
              Save Profile
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ProfileSettings
