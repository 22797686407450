import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px'
  },
  checkbox: {
    marginLeft: '-2%'
  }
})

interface BetaVisibilityInterface {
  isBetaUser: boolean
  setIsBetaUser: (value: boolean) => void
}

const BetaVisibility = ({
  isBetaUser,
  setIsBetaUser
}: BetaVisibilityInterface): React.ReactElement => {
  const classes = useStyles()
  const onChange = (): void => {
    setIsBetaUser(!isBetaUser)
  }

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isBetaUser}
            onChange={onChange}
            name='isBetaUser'
            color='primary'
            className={classes.checkbox}
          />
        }
        label='Opt in to Beta'
      />
    </div>
  )
}

export default BetaVisibility
