export enum UserStatus {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
  PENDING = 'Pending'
}

export default interface User {
  dateCreated?: any
  email?: string
  firstName?: string
  id?: string
  isRegisteredUSEntity?: boolean
  lastLogin?: string
  lastName?: string
  roadwayWelcome?: boolean
  role?: string
  status?: string
  tosAgreed?: any
}
