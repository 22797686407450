export const RB_GREEN = '#1a9641'
export const RB_LIGHT_GREEN = '#a6d96a'
export const RB_YELLOW = '#fad201'
export const RB_YELLOW_DARK_10 = '#e1bd00'
export const RB_ORANGE = '#f26622'
export const RB_RED = '#d7191c'
export const RB_BLACK = '#000'
export const RB_GREY = '#808080'
export const RB_DARK_GREY = '#343332'
export const PRIMARY_HOVER_COLOR = '#f26622'
export const RB_POTHOLE_ORANGE = '#ffc85c'
export const RB_SEAL = '#a8ecd0'
export const RB_CRACK = '#bfa9d1'
export const RB_WHITESMOKE = '#f5f5f5'
export const RB_LIGHT_GREY = '#eeeeee'
export const RB_VIDEO_LOGGER = '#548cff'
export const RB_SIDEBAR_BACKGROUND = '#e6e6e6'

export const TABLE_HEADER_BACKGROUND = '#fad105'
export const TABLE_HEADER_TEXT = '#282000'
export const TABLE_ROW_SHADING = '#f4f4f4'

export const RB_LIGHT_SHADOW = '#0003'

export const MUI_INPUT_BOTTOM_BORDER = 'rgba(0, 0, 0, 0.42)'
