import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MUIButton from "@material-ui/core/Button";
import { PropTypes } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
    textTransform: ({
      capitalizeLabel,
    }: {
      capitalizeLabel?: boolean;
    }): "uppercase" | "none" => (capitalizeLabel ? "uppercase" : "none"),
    "&:hover": {
      boxShadow: "none",
    },
    "&:disabled": {
      opacity: 0.6,
    },
    "&:active": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: `0px 0px 4px 0px #0003`,
    },
  },
});

export interface ButtonProps {
  capitalizeLabel?: boolean;
  disabled?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  variant?: "text" | "outlined" | "contained";
  color?: PropTypes.Color;
  type?: "button" | "reset" | "submit" | undefined;
  children: React.ReactNode;
  className?: string;
}

const Button = ({
  capitalizeLabel = true,
  disabled = false,
  onClick,
  variant = "contained",
  color = "default",
  type = "button",
  children,
  className,
}: ButtonProps): JSX.Element => {
  const classes = useStyles({ capitalizeLabel });
  return (
    <MUIButton
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      color={color}
      type={type}
      className={className}
      classes={classes}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
